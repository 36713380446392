import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import AsyncDropdown from "../../utils/asyncDropdown/AsyncDropdown";
import { getRolessBytenantId } from "../manageMembers/manageMember.api";
import { useSelector } from "react-redux";
// import { getAllPanel } from "../panel/panel.api";
import { getActivepanels } from "../managePanel/managePanel.api";
import MultipleValueDropDown from "../../utils/multipleValueDropDown/MultipleValueDropDown";
import { approveRequest } from "./Redemption.api";

const ConfRedemption = (props) => {
  const {
    open,
    hadnleCloseModal,
    type,
    setSuccess,
    setAlert,
    setError,
    editId,
    selectedItem,
    roleSelected,
    dataUpdated,
    setDataUpdated,
    panelGuid,
    actionStatus,
    confirmMessage,
  } = props;

  // states
  const [adminReasonError, setAdminReasonError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roles, setroles] = useState([]);
  const [panels, setPanels] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedPanels, setSelectedPanels] = useState([]);
  const adminId = useSelector((state) => state.auth.userData?.adminId);
  const [currentEmail, setCurrentEmail] = useState("");
  const [selectedPanel, setselectedPanel] = useState("");

  const [userData, setUserData] = useState({
    utid: "",
    status: "",
    redemptionStatus: "",
    disapprovedBy: null,
    disapprovedReasonForAdmin: selectedItem?.disapprovedReason,
    disapprovedReasonUser: selectedItem?.userReason,
    updatedBy: null,
  });

  useEffect(() => {
    // console.log("roles", roles);
    // console.log("type", type);
    if (selectedItem?.userReason != null) {
      setUserData({
        disapprovedReasonUser: selectedItem?.userReason,
        disapprovedReasonForAdmin: selectedItem?.disapprovedReason,
      });
    }

    return () => {
      setUserData({
        utid: "",
        status: "",
        redemptionStatus: "",
        disapprovedBy: null,
        disapprovedReasonForAdmin: "",
        disapprovedReasonUser: "",
        updatedBy: null,
      }); // Reset to null during cleanup
      setLoading(false);
    };
  }, [editId, selectedItem, open, roles]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setAdminReasonError(userData.disapprovedReasonForAdmin.trim() === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();    
    setLoading(true);
    
  if (!userData.disapprovedReasonForAdmin.trim()) {
    setAdminReasonError(true);
    setLoading(false);
    return;
  }

  setAdminReasonError(false);
    let newData = {};

    if (actionStatus == "reject") {
      newData = {
        utid: selectedItem.utid,
        status: "disapproved",
        redemptionStatus: "failed",
        disapprovedBy: adminId,
        disapprovedReason: userData.disapprovedReasonForAdmin,
        updatedBy: adminId,
      };
    } else if (actionStatus == "hold") {
      newData = {
        utid: selectedItem.utid,
        status: "hold",
        redemptionStatus: "complete",
        disapprovedReason: userData.disapprovedReasonForAdmin,
        userReason: userData.disapprovedReasonUser,
        approvedBy: adminId,
        updatedBy: adminId,
      };
    }

    approveRequest(selectedItem.id, newData, panelGuid)
      .then((res) => {
        if (
          ((res?.status === 201 || res?.status === 200) &&
            res?.data?.data?.status == "disapproved") ||
          res?.data?.data?.status === "hold"
        ) {
          setDataUpdated(!dataUpdated);
          setSuccess(res?.data?.data);
          setUserData({
            utid: "",
            status: "",
            redemptionStatus: "",
            disapprovedBy: null,
            disapprovedReasonForAdmin: selectedItem.disapprovedReason,
            disapprovedReasonUser: selectedItem.userReason,
            updatedBy: null,
          });
        } else if (res?.data?.message == "Invalid IP") {
          hadnleCloseModal();
          setError(res?.data?.message);
        } else {
          setError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog fullWidth={"true"} open={open} onClose={hadnleCloseModal}>
      <form onSubmit={handleSubmit} noValidate>
        <span className={"closeButton"} onClick={hadnleCloseModal}>
          <i className="bi bi-x"></i>
        </span>
        <DialogTitle>Reason for {confirmMessage}</DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }}>
          <Box padding={2}>
            {actionStatus == "hold" ? (
              <Stack direction={"column"} spacing={2}>
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"text"}
                  label={"Reason For Admin"}
                  name="disapprovedReasonForAdmin"
                  error={adminReasonError} // ✅ Shows red border only when input is empty
                  helperText={adminReasonError ? "This field is required" : ""} // ✅ Shows helper text only when input is empty
                  required
                  value={userData.disapprovedReasonForAdmin}
                  variant="outlined"
                  multiline
                  className="redemptionReasonInput"
                  rows={3}
                />
                {/* <div className="reasontop-line">
                    <div>Reason For User</div>
                </div> */}
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"text"}
                  label={"Reason for User"}
                  name="disapprovedReasonUser"
                  value={userData.disapprovedReasonUser}
                  className="redemptionReasonInput"
                  multiline
                  rows={3}
                />
              </Stack>
            ) : (
              <Stack direction={"column"} spacing={2}>
                <TextField
                  onChange={handleInputChange}
                  fullWidth
                  type={"text"}
                  label={"Reason"}
                  name="disapprovedReasonForAdmin"
                  required
                  value={userData.disapprovedReasonForAdmin}
                />
              </Stack>
            )}
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "0 24px 24px 20px" }}>
          <LoadingButton
            // onClick={() => {handleSubmit()}}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            type={"submit"}
          >
            {/* Disapprove */}

            {confirmMessage}
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={() => {
              hadnleCloseModal();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfRedemption;
